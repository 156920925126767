<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder h2back m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Ficha de la zona
      </h2>
    </div>
    <b-row v-if="locationPrepare">
      <b-col cols="12">
        <b-card class="height-card">
          <b-row class="position-relative">
            <b-col cols="12">
              <p class="pTitle">Zona</p>
              <div class="d-flex">
                <h3 class="font-weight-bolder hTitle m-0">
                  {{ location.name }}
                </h3>
              </div>
            </b-col>

            <b-col cols="12">
              <hr>
              <b-row>
                <b-col v-if="location.project" xl="4" md="6" class="product_info">
                  <p class="m-0"><strong>Obra:</strong></p>
                  <b-link :to="{ name: 'viewProject', params: { id: location.project.id} }">
                    {{ location.project.name }}
                  </b-link>
                </b-col>

                <b-col v-if="location.description" cols="12" class="product_info mt-1">
                  <p class="m-0"><strong>Descripción:</strong></p>
                  <div v-html="location.description"></div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="text-right pr-2">
              <b-link :to="{ name: 'editLocation', params: { id: location.id} }" class="d-inline-block btn-primary">
                <span class="">Editar</span>
                <span class=" pl-1">
                  <feather-icon icon="Edit2Icon" />
                </span>
              </b-link>
              <span v-if="currentUser.roles[0].name === 'super_admin'" class="d-inline-block ml-1 btn btn-danger"
                @click="deleteProject(location, location.name)">
                <span class="">Eliminar</span>
                <span class=" pl-1">
                  <feather-icon icon="Trash2Icon" />
                </span>
              </span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BCollapse, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { config } from '@/shared/app.config'

export default {
  components: {
    BButton,
    BLink,
    BRow,
    BCol,
    BCard,
    BCollapse,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: '',
      statusProjectsVariant: config.statusProjectsVariant,
      statusProjectsText: config.statusProjectsText,
      statusJobsVariant: config.statusJobsVariants,
      statusJobsText: config.statusJobsText,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      collapseInfo: false,
      locationPrepare: false,
      pages: ['10', '15', '25'],
      date_ini: '',
      date_fin: '',
      status: '',
      searchTerm: '',
      visibleFilter: false,
    }
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.currentPage = 1
      this.chargeData()
    },
    date_fin() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      location: 'locations/getLocation',
      currentUser: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      getLocation: 'locations/getLocation',
      delete: "locations/delete",
    }),
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    deleteProject(location, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(location);
          }
        });
    },
    chargeData() {
      if (this.location) {
        this.locationPrepare = true
      }
    }
  },
  async created() {
    await this.getLocation(this.$route.params.id)
    this.chargeData()
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
